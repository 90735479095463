import BriaLogoPurple from "../../assets/bria_logo_purple.svg";
import ArrowRightWhite from "../../assets/icons/arrow_right_white.svg";
import ArrowLeftWhite from "../../assets/icons/arrow_left_white.svg";
import HomeIconPurple from "../../assets/icons/home_icon_purple.svg";
import UserIconPurple from "../../assets/icons/user_icon_purple.svg";
import CashIconPurple from "../../assets/icons/cash_icon_purple.svg";
import ConfigIconPurple from "../../assets/icons/config_icon_purple.svg";
import SubAccountIconPurple from "../../assets/icons/sub_account_icon_purple.svg";
import RelatoryIconPurple from "../../assets/icons/relatory_icon_purple.svg";
import FeedbackIconPurple from "../../assets/icons/feedback_icon_purple.svg";
import LockIcon from "../../assets/icons/lock_icon.svg";
import LogoutIcon from "../../assets/icons/logout_icon_purple.svg";

import { SideBarContainer, WrapperTouch, NavOption } from "./styled";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { clearLs } from "../../localStorage";

enum DashboardPages {
  HOME = "Home",
  LEADS = "Leads",
  MY_VALUE = "Meus Valores",
  CONFIG = "Personalizar",
  SUB_ACCOUNT = "Subcontas",
  RELATORY = "Relatórios",
  FEEDBACK = "Feeback",
  CHANGE_PASSWORD = "Trocar Senha",
  LOGOUT = "Logout",
}

type SideNavBarProps = {
  pageSelected: DashboardPages;
  setPageSelected: React.Dispatch<React.SetStateAction<DashboardPages>>;
};

export const SideNavBar: React.FC<SideNavBarProps> = ({
  pageSelected,
  setPageSelected,
}: SideNavBarProps) => {
  const history = useHistory();

  const [barIsOpen, setBarIsOpen] = useState(true);

  useEffect(() => {
    if (history.location.pathname === "/") {
      setPageSelected(DashboardPages.HOME);
    }
    if (history.location.pathname === "/leads") {
      setPageSelected(DashboardPages.LEADS);
    }
    if (history.location.pathname === "/relatory") {
      setPageSelected(DashboardPages.RELATORY);
    }
    if (history.location.pathname === "/feedback") {
      setPageSelected(DashboardPages.FEEDBACK);
    }
  }, [history.location]);

  return (
    <SideBarContainer barIsOpen={barIsOpen}>
      <WrapperTouch
        onClick={() => {
          setBarIsOpen(!barIsOpen);
        }}
      >
        <div>
          <img src={BriaLogoPurple} alt="" />
          {barIsOpen ? (
            <img src={ArrowLeftWhite} alt="" />
          ) : (
            <img src={ArrowRightWhite} alt="" />
          )}
        </div>
      </WrapperTouch>
      <NavOption
        barIsOpen={barIsOpen}
        isSelected={pageSelected === DashboardPages.HOME}
        onClick={() => {
          setPageSelected(DashboardPages.HOME);
          history.push("/");
        }}
      >
        <hr />
        <div>
          <img src={HomeIconPurple} alt="" />
          <p>{DashboardPages.HOME}</p>
        </div>
      </NavOption>
      <NavOption
        barIsOpen={barIsOpen}
        isSelected={pageSelected === DashboardPages.LEADS}
        onClick={() => {
          setPageSelected(DashboardPages.LEADS);
          history.push("/leads");
        }}
      >
        <hr />
        <div>
          <img src={UserIconPurple} alt="" />
          <p>{DashboardPages.LEADS}</p>
        </div>
      </NavOption>

      {/* <NavOption
        barIsOpen={barIsOpen}
        isSelected={pageSelected === DashboardPages.MY_VALUE}
        onClick={() => {
          setPageSelected(DashboardPages.MY_VALUE);
        }}
      >
        <hr />
        <div>
          <img src={CashIconPurple} alt="" />
          <p>{DashboardPages.MY_VALUE}</p>
        </div>
      </NavOption> */}

      {/* <NavOption
        barIsOpen={barIsOpen}
        isSelected={pageSelected === DashboardPages.CONFIG}
        onClick={() => {
          setPageSelected(DashboardPages.CONFIG);
        }}
      >
        <hr />
        <div>
          <img src={ConfigIconPurple} alt="" />
          <p>{DashboardPages.CONFIG}</p>
        </div>
      </NavOption> */}
      {/* <NavOption
        barIsOpen={barIsOpen}
        isSelected={pageSelected === DashboardPages.SUB_ACCOUNT}
        onClick={() => {
          setPageSelected(DashboardPages.SUB_ACCOUNT);
        }}
      >
        <hr />
        <div>
          <img src={SubAccountIconPurple} alt="" />
          <p>{DashboardPages.SUB_ACCOUNT}</p>
        </div>
      </NavOption> */}
      <NavOption
        barIsOpen={barIsOpen}
        isSelected={pageSelected === DashboardPages.RELATORY}
        onClick={() => {
          setPageSelected(DashboardPages.RELATORY);

          history.push("/relatory");
        }}
      >
        <hr />
        <div>
          <img src={RelatoryIconPurple} alt="" />
          <p>{DashboardPages.RELATORY}</p>
        </div>
      </NavOption>
      <NavOption
        barIsOpen={barIsOpen}
        isSelected={pageSelected === DashboardPages.FEEDBACK}
        onClick={() => {
          setPageSelected(DashboardPages.FEEDBACK);
          history.push("/feedback");
        }}
      >
        <hr />
        <div>
          <img src={FeedbackIconPurple} alt="" />
          <p>{DashboardPages.FEEDBACK}</p>
        </div>
      </NavOption>
      {/* <NavOption
        barIsOpen={barIsOpen}
        isSelected={pageSelected === DashboardPages.CHANGE_PASSWORD}
        onClick={() => {
          setPageSelected(DashboardPages.CHANGE_PASSWORD);
        }}
      >
        <hr />
        <div>
          <img src={LockIcon} alt="" />
          <p>{DashboardPages.CHANGE_PASSWORD}</p>
        </div>
      </NavOption>
 */}
      <NavOption
        barIsOpen={barIsOpen}
        isSelected={pageSelected === DashboardPages.LOGOUT}
        onClick={() => {
          clearLs();
          history.push("/login");
        }}
      >
        <hr />
        <div>
          <img src={LogoutIcon} alt="" />
          <p>{DashboardPages.LOGOUT}</p>
        </div>
      </NavOption>
    </SideBarContainer>
  );
};
