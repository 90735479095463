import React from "react";
import { Switch } from "react-router-dom";
import { MasterPage } from "./masterPage/index";
import Route from "./Route";

import { Login } from "../pages/login/index";
import { Dashboard } from "../pages/dashboard/index";
import { Leads } from "../pages/leads/index";
import { Relatory } from "../pages/relatory/index";
import { Feedback } from "../pages/feedback/index";

const Routes: React.FC = () => (
  <Switch>
    <Route path="/login" exact component={Login} />
    <MasterPage path="/" exact isPrivate component={Dashboard} />
    <MasterPage path="/leads" exact isPrivate component={Leads} />
    <MasterPage path="/relatory" exact isPrivate component={Relatory} />
    <MasterPage path="/feedback" exact isPrivate component={Feedback} />
  </Switch>
);

export default Routes;
