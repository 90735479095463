import BriaLogoPurple from "../../assets/bria_logo_purple.svg";
import MailIcon from "../../assets/icons/mail_icon.svg";
import LockIcon from "../../assets/icons/lock_icon.svg";
import ArrowRightIcon from "../../assets/icons/arrow_right_purple.svg";
import { setLsUserToken } from "../../localStorage/index";

import { BriaFooter } from "../../components/BriaFooter/index";
import {
  LoginContainer,
  BannerHeader,
  LoginContent,
  FormContent,
  LoginForm,
  LoginInput,
  LoginButton,
} from "./styled";
import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { request } from "../../services/request";

export const Login: React.FC = () => {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onHandleSubmit = async () => {
    const user = { login: email, password };
    const response = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "POST",
      path: `users/login`,
      data: user,
    });
    if (response.access_token) {
      setLsUserToken(`Bearer ${response.access_token}`);
      history.push("/");
    }
  };
  return (
    <LoginContainer>
      <BannerHeader>
        <div>
          <img src={BriaLogoPurple} alt="" />
          <h2>
            Inteligência
            <br /> antecipa futuro.
          </h2>
        </div>
      </BannerHeader>
      <LoginContent>
        <FormContent>
          <LoginForm>
            <LoginInput>
              <img src={MailIcon} alt="" />
              <input
                type="text"
                placeholder="E-mail"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </LoginInput>
            <LoginInput>
              <img src={LockIcon} alt="" />
              <input
                type="password"
                placeholder="Senha"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </LoginInput>

            <LoginButton onClick={onHandleSubmit}>
              <span>Entrar</span> <img src={ArrowRightIcon} alt="" />
            </LoginButton>
          </LoginForm>
          {/* <span>
            Primeiro acesso? <a href="#">Se cadastre.</a>
          </span> */}
        </FormContent>
        <BriaFooter />
      </LoginContent>
    </LoginContainer>
  );
};
