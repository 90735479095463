import styled from "styled-components";
import BackgroundHeaderBanner from "../../assets/Banner_dash_login.png";

export const LoginContainer = styled.div`
  background: #ffffff;
  max-height: 100vh;
`;

export const BannerHeader = styled.div`
  width: 100%;
  height: 35vh;
  max-height: 375px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: url(${BackgroundHeaderBanner}) center;
  background-size: cover;
  div {
    z-index: 10;
    margin-left: 10%;
    align-self: flex-start;
    & > img {
      margin-bottom: 5%;
    }
    & > h2 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 54px;
      color: #2f054d;
    }
  }
`;

export const LoginContent = styled.div`
  background: #ffffff;
  width: 100%;
  height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  & > footer {
    margin-bottom: 2.5%;
  }
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  & > span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 27px;
    letter-spacing: -0.015em;

    color: #2f054d;
    a {
      margin-left: 4px;
      font-weight: bold;
      font-size: 14px;

      text-decoration-line: underline;
      color: #2f054d;
    }
  }
`;

export const LoginForm = styled.div`
  height: 40vh;
  max-height: 300px;
  max-width: 500px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

export const LoginInput = styled.div`
  border-bottom: 2px solid rgba(47, 5, 77, 1);
  width: 90%;
  min-width: 250px;
  min-height: 48px;
  display: flex;
  align-items: center;
  & > img {
    padding: 0 12px;
  }
  & > input {
    height: 100%;
    width: 100%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 105.18%;

    letter-spacing: -0.03em;

    color: #2f054d;
  }
`;

export const LoginButton = styled.button`
  min-width: 200px;
  cursor: pointer;
  width: 100%;
  height: 54px;
  border: 2px solid #2f054d;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  align-items: center;
  position: relative;
  transition: all linear 0.3s;
  & > span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 105.18%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #2f054d;
    margin: auto;
  }
  & > img {
    margin: auto;
    position: absolute;
    right: 12px;
    width: 12px;
  }
  &:hover {
    border: #fff;
    background: #2f054d;
    & > span {
      color: #fff;
    }
    & > img {
      filter: invert();
    }
  }
`;
