import { useState } from "react";
import { useHistory } from "react-router";
import ArrowRightIcon from "../../assets/icons/arrow_right_purple.svg";
import { request, getPayload } from "../../services/request";
import { FeedbackContainer } from "./styled";

export const Feedback: React.FC = () => {
  const history = useHistory();
  const [feedback, setFeedback] = useState("");
  const handleSendFeedback = async () => {
    const payload = getPayload();
    const response = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "POST",
      path: `feedback`,
      data: {
        userId: payload.id,
        message: feedback,
      },
    });
    if (!response.error) {
      history.push("/");
    }
  };
  return (
    <FeedbackContainer>
      <h1>Feedback</h1>
      <div>
        <p>
          Nos envie sua crítica e sugestão para melhorarmos o nosso produto:
        </p>
        <textarea
          onBlur={(e) => {
            setFeedback(e.target.value);
          }}
        />
        <div>
          <button onClick={handleSendFeedback}>
            <span>Enviar</span>
            <img src={ArrowRightIcon} alt="" />
          </button>
        </div>
      </div>
    </FeedbackContainer>
  );
};
