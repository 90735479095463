import jwtDecode, { JwtPayload } from "jwt-decode";

export const setLsUserToken = (value: string) => {
  window.localStorage.setItem(process.env.REACT_APP_KEY_TOKEN ?? "", value);
};

const isTokenExpired = (token: string) => {
  try {
    const decodedToken: JwtPayload = jwtDecode(token.split(" ")[1]);
    const currentTime = Date.now() / 1000;
    if (decodedToken && decodedToken.exp) {
      return currentTime > decodedToken?.exp;
    }
    return true;
  } catch (error) {
    return true;
  }
};

export const getLsUserToken = () => {
  const token = window.localStorage.getItem(
    process.env.REACT_APP_KEY_TOKEN ?? ""
  );
  if (token) {
    const isExpiredToken = isTokenExpired(token);
    if (!isExpiredToken) {
      return token;
    }
  }
  return "";
};

export const setLsAccountsSelected = (value: any) => {
  window.localStorage.setItem(
    process.env.REACT_APP_KEY_ACCOUNTS_SELECTED ?? "",
    JSON.stringify(value)
  );
};

export const getLsAccountsSelected = () => {
  let value = window.localStorage.getItem(
    process.env.REACT_APP_KEY_ACCOUNTS_SELECTED ?? ""
  );

  return value ? JSON.parse(value) : null;
};

export const clearLs = () => {
  window.localStorage.clear();
};

export const setLsNameAccountSelected = (value: any) => {
  window.localStorage.setItem(
    process.env.REACT_APP_NAME_ACCOUNTS_SELECTED ?? "",
    JSON.stringify(value)
  );
};

export const getLsNameAccountSelected = () => {
  let names = window.localStorage.getItem(
    process.env.REACT_APP_NAME_ACCOUNTS_SELECTED ?? ""
  );

  return names ? JSON.parse(names) : null;
};
