import React from "react";
import ReactDOM from "react-dom";
import { AccountsSelectedProvider } from "./hooks/useAccountsSelected";

import App from "./App";

ReactDOM.render(
  <AccountsSelectedProvider>
    <App />
  </AccountsSelectedProvider>,
  document.getElementById("root")
);
