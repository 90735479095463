import styled from "styled-components";

export const FooterContent = styled.footer`
  border-bottom: 5px solid rgba(47, 5, 77, 0.3);
  width: 80vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;

  & > p {
    width: fit-content;
    height: fit-content;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.03em;
    color: #2f054d;
  }
  & > img {
    max-width: 70px;
  }
`;
