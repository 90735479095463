import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Store } from "./redux/store";
import Loader from "./components/loader";
import GlobalStyle from "./globalStyled";
import Routes from "./routes/index";
import "rsuite/lib/styles/index.less";
import "rsuite/dist/styles/rsuite-default.css";

const App: React.FC = () => (
  <Provider store={Store}>
    <BrowserRouter>
      <Routes />
      <GlobalStyle />
      <Loader />
    </BrowserRouter>
  </Provider>
);

export default App;
