import axios, { AxiosRequestConfig } from "axios";
import { Dispatch } from "../redux/store";
import sweetAlert from "../utils/sweetAlert";
const SuccessAlert = sweetAlert.success;
const ErrorAlert = sweetAlert.error;

interface IPayload {
  id: string;
  accountId: string;
}

interface IRequest {
  showLoading?: Boolean;
  loadingMessage?: string;
  timeout?: number;
  showSuccessMessage?: Boolean;
  showErrorMessage?: Boolean;
  responseType?: any;
  method:
    | "get"
    | "GET"
    | "delete"
    | "DELETE"
    | "head"
    | "HEAD"
    | "options"
    | "OPTIONS"
    | "post"
    | "POST"
    | "put"
    | "PUT"
    | "patch"
    | "PATCH"
    | "purge"
    | "PURGE"
    | "link"
    | "LINK"
    | "unlink"
    | "UNLINK"
    | undefined;
  path: string;
  baseUrl?: string;
  data?: any;
  query?: any;
}

export const request = (params: IRequest): Promise<any> => {
  const showLoading =
    params.showLoading !== undefined ? params.showLoading : true;
  const loadingMessage =
    params.loadingMessage !== undefined ? params.loadingMessage : "Carregando";
  const timeout = params.timeout !== undefined ? params.timeout : 10000;
  const showSuccessMessage =
    params.showSuccessMessage !== undefined ? params.showSuccessMessage : true;
  const showErrorMessage =
    params.showErrorMessage !== undefined ? params.showErrorMessage : true;
  const method = params.method;
  const path = params.path;
  const baseUrl = params.baseUrl;
  const responseType = params.responseType;
  let data = params.data;

  let showLoadingTimeout: any = null;
  if (showLoading) {
    showLoadingTimeout = setTimeout(function () {
      Dispatch({
        type: "show_loader",
        show: true,
      });
      Dispatch({
        type: "loader_set_message",
        message: loadingMessage,
      });
    }, 500);
  }

  const base_url = !baseUrl ? process.env.REACT_APP_BASE_API_URL : baseUrl;
  return new Promise(async (resolve, reject) => {
    let config: AxiosRequestConfig = {
      method: method,
      url: `${base_url}/${path}`,
      timeout: timeout,
      headers: {},
      responseType,
    };

    let token = window.localStorage.getItem(
      process.env.REACT_APP_KEY_TOKEN ?? ""
    );

    if (token) {
      config.headers.Authorization = token;
    }

    if (data) {
      config.data = data;
    }

    return axios(config)
      .then((response) => {
        clearTimeout(showLoadingTimeout);
        if (showLoading) {
          Dispatch({
            type: "show_loader",
            show: false,
          });
        }

        if (showSuccessMessage) {
          SuccessAlert(response.data.msg);
        }
        resolve(response.data);
      })
      .catch((error) => {
        clearTimeout(showLoadingTimeout);
        if (showLoading) {
          Dispatch({
            type: "show_loader",
            show: false,
          });
        }
        if (showErrorMessage) {
          if (error.response && error.response.data) {
            if (error.response.data.errors) {
              ErrorAlert(error.response.data.errors.join("\n"));
            } else {
              ErrorAlert(error.response.data.msg);
            }
          }
        }
        resolve({
          error: error.response ? error.response.data : "timeout",
        });
      });
  });
};

function parseJwt(token: string) {
  const tokenOnly = token.split(" ")[1];
  var base64Url = tokenOnly.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const getPayload = (): IPayload => {
  const token = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN ?? "");
  if (!token) {
    return {} as IPayload;
  }

  return parseJwt(token);
};

export async function getReport(type: string) {
  try {
    Dispatch({
      type: "show_loader",
      show: true,
    });
    const res = await axios.get(`/leads/${type}`, { responseType: "blob" });
    Dispatch({
      type: "show_loader",
      show: false,
    });
    return res.data;
  } catch (e) {
    Dispatch({
      type: "show_loader",
      show: false,
    });
    console.log(e);
    return false;
  }
}
