import styled, { css } from "styled-components";

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow: auto;
  max-width: 100vw;
  overflow: unset;
  padding-right: 64px;
  padding-bottom: 32px;
  background-color: #f7f7f7;
  .menu-calendar {
    left: initial !important;
    right: 0 !important;
  }
  .rs-picker-daterange-menu {
    left: initial !important;
    right: 0 !important;
  }
  .rs-picker-menu {
    right: 0 !important;
    left: initial !important;
  }
`;

export const ChartContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

export const SingleChart = styled.div`
  width: 30%;
  border-right: 1px solid hsla(0, 0%, 77%, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  .error-chart {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 1px solid hsla(0, 0%, 77%, 1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:last-child {
    border: none;
  }
`;

export const ChartContent = styled.div`
  margin-top: 16px;
  width: 100%;
  min-height: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 5px 13px -6px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
`;

export const StatusInfoContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      font-family: Open Sans;
      font-weight: normal;
      font-size: 12px;
      color: #676767;
    }
    h4 {
      font-family: Open Sans;
      font-weight: bold;
      font-size: 25px;
      color: #2f054d;
    }
  }
`;

export const RelatoryHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-family: Open Sans;
    font-weight: bold;
    font-size: 40px;
    line-height: 245.5%;
    color: #2f054d;
  }
`;

export const FilterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;
  & > img {
    cursor: pointer;
    margin-right: 16px;
  }
`;

export const SearchFilterContent = styled.div`
  margin-top: 4vh;
  height: 10vh;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 16px;
    button {
      background-color: #fff;
      cursor: pointer;
      width: 201px;
      height: 38px;
      box-sizing: border-box;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #2f054d;
      transition: all linear 0.4s;

      span {
        font-family: Open Sans;
        font-size: 14px;
        color: #2f054d;
      }
      img {
        margin-left: 8px;
      }
    }

    button:first-child {
      &:hover {
        opacity: 0.6;
      }
    }
    button:last-child {
      margin-left: 16px;
      background: #2f054d;
      border: 2px solid #2f054d;
      &:hover {
        opacity: 0.6;
      }
      span {
        color: #fff;
      }
    }
  }
`;

export const SearchInput = styled.div`
  width: 271px;
  height: 38px;
  border: 2px solid #c9bfd1;
  box-sizing: border-box;
  border-radius: 15px;
  & > input {
    width: 100%;
    height: 100%;
    border-radius: 15px 0 0 15px;
    padding-left: 8px;

    font-family: Open Sans;
    font-size: 16px;
    color: #676767;
    opacity: 0.5;
  }
  & > button {
    cursor: pointer;
    border: none !important;
    background: #fff !important;
    width: 20% !important;
    height: 100% !important;
    border-radius: 0 15px 15px 0;
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const RelatoryDataContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  & > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    border: solid 2px #676767;
    border-radius: 12px;
    padding: 15px;
    cursor: pointer;
    ${({ infoOpen }: { infoOpen: boolean }) =>
      !infoOpen
        ? css`
            img {
              transform: rotate(180deg);
            }
          `
        : css`
            img {
              transform: rotate(0deg);
            }
          `}
  }
  & > div:last-child {
    ${({ infoOpen }: { infoOpen: boolean }) =>
      !infoOpen
        ? css`
            display: flex;
          `
        : css`
            display: none;
            height: 0px;
          `}
    flex-direction: column;
    align-items: center;
    & > div {
      min-height: 70px;
      padding: 0 8px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px #676767;
      & > h3 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color: #676767;
      }
      & > h4 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;

        color: #2f054d;
      }
    }
  }
`;

export const MappingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const MappingCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const MappingCard = styled.div`
  width: 30%;
  min-width: 200px;
  height: 300px;
  background: #ffffff;
  box-shadow: 0px 5px 13px -6px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  & > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 75%;
    overflow: auto;
    border-radius: 0 0 20px 20px;
    & > div:first-child {
      margin-top: 8px;
    }
    & > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > h3 {
        font-size: 18px;
        color: #676767;
      }
      & > h4 {
        font-weight: bold;
        font-size: 18px;

        color: #2f054d;
      }
    }
  }
  & > header {
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: solid 1px #676767;
    & > h4 {
      font-family: Open Sans;
      font-weight: bold;
      font-size: 16px;

      color: #2f054d;
    }
  }
`;

export const ProductsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const ProductsContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  & > div {
    width: 100%;
    height: 77px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 12px;
    margin-top: 8px;
    padding: 0 16px;
    & > h3 {
      font-family: Open Sans;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;

      color: #2f054d;
    }
    & > h4 {
      font-family: Open Sans;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      color: #2f054d;
    }
  }
`;
