export const percentualConverter = (count: number, total: number) => {
  return (count * 100) / total;
};

export const ageCalc = (birthday: Date) => {
  let age = new Date().getFullYear() - new Date(birthday).getFullYear();
  if (age > 56 && age < 65) {
    return "56-65";
  }
  if (age > 46 && age < 55) {
    return "46-55";
  }
  if (age > 36 && age < 45) {
    return "36-45";
  }
  if (age > 26 && age < 35) {
    return "26-35";
  } else {
    return "Outras faixas";
  }
};
