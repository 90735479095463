import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { request } from "../services/request";
// import { setLsAccountsSelected, getLsAccountsSelected } from "../localStorage";

type AccountsSelectedProviderProps = {
  children: ReactNode;
};

type IAccountsSelectedContextData = {
  setAcountsSelected: (accounts: string) => void;
  accountsSelected: Array<string>;
  accountName: string;
  subAccounts: Array<any>;
  getAccounts: (accountId: string) => void;
};

const AccountsSelectedContext = createContext<IAccountsSelectedContextData>(
  {} as IAccountsSelectedContextData
);

export const AccountsSelectedProvider = ({
  children,
}: AccountsSelectedProviderProps) => {
  const [subAccounts, setSubAccounts] = useState<Array<any>>([]);
  const [accountsSelected, setAccountsSelected] = useState<Array<string>>([]);
  const [accountName, setAccountsName] = useState<string>("");

  const setAcountsSelected = (accountId: string) => {
    let oldAccountsIds = accountsSelected;
    if (oldAccountsIds.indexOf(accountId) > -1) {
      oldAccountsIds = oldAccountsIds.filter(
        (oldAccountId) => oldAccountId !== accountId
      );
    } else {
      oldAccountsIds.push(accountId);
    }
    // setLsAccountsSelected(oldAccountsIds);
    setAccountsSelected(() => [...oldAccountsIds]);
  };

  const getAccounts = async (accountId: string) => {
    const req = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "GET",
      path: `accounts/clients/${accountId}`,
    });
    console.log("req", req);
    if (!req.error) {
      const Accounts: any[] = [];
      req.subAccounts.forEach((sub: any) => {
        Accounts.push({
          name: sub.name,
          _id: sub._id,
          logo: sub.logo,
        });
      });
      setSubAccounts(Accounts);
      setAccountsName(req.mainAccount);
    }
  };

  useEffect(() => {
    const selectAccounts: string[] = [];
    subAccounts.forEach((account) => {
      selectAccounts.push(account._id);
    });
    setAccountsSelected(selectAccounts);
  }, [subAccounts]);

  // useEffect(() => {
  //   if (getLsAccountsSelected() && getLsAccountsSelected().length > 0) {
  //     setAccountsSelected(() => [...getLsAccountsSelected()]);
  //   }
  // }, []);

  return (
    <AccountsSelectedContext.Provider
      value={{
        setAcountsSelected,
        getAccounts,
        subAccounts,
        accountsSelected,
        accountName,
      }}
    >
      {children}
    </AccountsSelectedContext.Provider>
  );
};

export const useAccountsSelected = () => {
  const context = useContext(AccountsSelectedContext);
  return context;
};
