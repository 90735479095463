import styled from "styled-components";

export const DashboardContainer = styled.div`
  background-color: #f7f7f7;
  display: flex;
`;
export const DashboardContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 3vw;
  width: 100%;
`;
