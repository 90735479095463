import BriaLogoPurple from "../../assets/bria_logo_purple.svg";
import { FooterContent } from "./styled";

export const BriaFooter: React.FC = () => {
  return (
    <FooterContent>
      <img src={BriaLogoPurple} alt="" />
      <p>Bria © 2021</p>
    </FooterContent>
  );
};
