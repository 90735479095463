import styled, { css } from "styled-components";

export const RelatoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  max-width: 100vw;
  overflow: unset;
  padding-right: 64px;
  padding-bottom: 32px;

  .menu-calendar {
    left: initial !important;
    right: 0 !important;
  }
  .rs-picker-daterange-menu {
    left: initial !important;
    right: 0 !important;
  }
  .rs-picker-menu {
    right: 0 !important;
    left: initial !important;
  }
`;

export const RelatoryHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-family: Open Sans;
    font-weight: bold;
    font-size: 40px;
    line-height: 245.5%;
    color: #2f054d;
  }
`;

export const FilterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;
  & > img {
    cursor: pointer;
    margin-right: 16px;
  }
`;

export const SearchFilterContent = styled.div`
  margin-top: 4vh;
  height: 10vh;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 16px;
    button {
      background-color: #fff;
      cursor: pointer;
      width: 201px;
      height: 38px;
      box-sizing: border-box;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #2f054d;
      transition: all linear 0.4s;

      span {
        font-family: Open Sans;
        font-size: 14px;
        color: #2f054d;
      }
      img {
        margin-left: 8px;
      }
    }

    button:first-child {
      &:hover {
        opacity: 0.6;
      }
    }
    button:last-child {
      margin-left: 16px;
      background: #2f054d;
      border: 2px solid #2f054d;
      &:hover {
        opacity: 0.6;
      }
      span {
        color: #fff;
      }
    }
  }
`;

export const SearchInput = styled.div`
  width: 271px;
  height: 38px;
  border: 2px solid #c9bfd1;
  box-sizing: border-box;
  border-radius: 15px;
  & > input {
    width: 100%;
    height: 100%;
    border-radius: 15px 0 0 15px;
    padding-left: 8px;

    font-family: Open Sans;
    font-size: 16px;
    color: #676767;
    opacity: 0.5;
  }
  & > button {
    cursor: pointer;
    border: none !important;
    background: #fff !important;
    width: 20% !important;
    height: 100% !important;
    border-radius: 0 15px 15px 0;
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const RelatoryDataContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  & > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    border: solid 2px #676767;
    border-radius: 12px;
    padding: 15px;
    cursor: pointer;
    ${({ infoOpen }: { infoOpen: boolean }) =>
      !infoOpen
        ? css`
            img {
              transform: rotate(180deg);
            }
          `
        : css`
            img {
              transform: rotate(0deg);
            }
          `}
  }
  & > div:last-child {
    ${({ infoOpen }: { infoOpen: boolean }) =>
      !infoOpen
        ? css`
            display: flex;
          `
        : css`
            display: none;
            height: 0px;
          `}
    flex-direction: column;
    align-items: center;
    & > div {
      min-height: 70px;
      padding: 0 8px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px #676767;
      & > h3 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color: #676767;
      }
      & > h4 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;

        color: #2f054d;
      }
    }
  }
`;
