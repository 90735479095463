import {
  FilterContainer,
  RightArea,
  AccountsToSelectedArea,
  CorrectIcon,
} from "./styled";
import Accountselector from "../../assets/icons/client_selector.svg";
import { useEffect, useState } from "react";
import { getPayload, request } from "../../services/request";
import correctIconGray from "../../assets/icons/correctIconGray.svg";
import correctIconBlue from "../../assets/icons/correctIconBlue.png";
// import {
//   setLsAccountsSelected,
//   getLsAccountsSelected,
//   getLsNameAccountSelected,
//   setLsNameAccountSelected,
// } from "../../localStorage";
import { useAccountsSelected } from "../../hooks/useAccountsSelected";
import { useHistory } from "react-router";

export const FilterTopSideBar: React.FC = () => {
  const history = useHistory();
  const [openAccountsMenu, setOpenAccountsMenu] = useState<boolean>(false);
  // const [subAccounts, setAccounts] = useState<
  //   Array<{ name: string; logo: string; _id: string }>
  // >([]);
  // const { subAccounts } = useAccountsSelected();

  const [subAccountsSelected, setSubAccountsSelected] = useState<Array<string>>(
    []
  );
  const payload = getPayload();
  const accountId = payload.accountId;
  const {
    subAccounts,
    getAccounts,
    accountsSelected,
    setAcountsSelected,
    accountName,
  } = useAccountsSelected();

  // const getAccounts = async () => {
  //   if (!accountId) return history.push("/login");
  //   const req = await request({
  //     showLoading: true,
  //     showSuccessMessage: false,
  //     method: "GET",
  //     path: `accounts/clients/${accountId}`,
  //   });
  //   console.log("req", req);
  //   if (!req.error) {
  //     setMainAccount(req.mainAccount);
  //     setAccounts(req.subAccounts);
  //     if (!getLsAccountsSelected() || getLsAccountsSelected().length === 0) {
  //       req.subAccounts.forEach((subAcc: { _id: string }) => {
  //         setAcountsSelected(subAcc._id);
  //       });
  //     }
  //     if (
  //       !getLsNameAccountSelected() ||
  //       getLsNameAccountSelected().length === 0
  //     ) {
  //       const namesAccounts: any[] = [];
  //       req.subAccounts.forEach((acc: { name: string; _id: string }) => {
  //         namesAccounts.push({ name: acc.name, id: acc._id });
  //       });
  //       setLsNameAccountSelected(namesAccounts);
  //     }
  //   }
  // };

  const handleSelectedDeselectedAccount = (accountId: string) => {
    setAcountsSelected(accountId);
  };

  const handleOpenAccountMenu = () => {
    setOpenAccountsMenu(!openAccountsMenu);
  };

  useEffect(() => {
    getAccounts(accountId);
  }, []);

  return (
    <FilterContainer>
      <div>
        <h3>{accountName}</h3>
        {history?.location?.pathname !== "/leads" && (
          <RightArea>
            {subAccounts && subAccounts.length > 1 && (
              <img
                src={Accountselector}
                alt="Icone selecionar cliente"
                onClick={handleOpenAccountMenu}
              />
            )}
            {openAccountsMenu && subAccounts && subAccounts.length > 0 && (
              <AccountsToSelectedArea>
                {subAccounts.map((account) => {
                  return (
                    <div
                      key={account._id}
                      onClick={() =>
                        handleSelectedDeselectedAccount(account._id)
                      }
                    >
                      {accountsSelected.indexOf(account._id) > -1 ? (
                        <img
                          src={account.logo.replace("{cor}", "azul")}
                          alt=""
                        />
                      ) : (
                        <img
                          src={account.logo.replace("{cor}", "cinza")}
                          alt=""
                        />
                      )}

                      {accountsSelected.indexOf(account._id) > -1 ? (
                        <CorrectIcon src={correctIconBlue} />
                      ) : (
                        <CorrectIcon src={correctIconGray} />
                      )}
                    </div>
                  );
                })}
              </AccountsToSelectedArea>
            )}
          </RightArea>
        )}
      </div>
    </FilterContainer>
  );
};
