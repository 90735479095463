import styled from "styled-components";

export const LeadsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  max-width: 90vw;
  overflow: unset;
  padding-right: 64px;
  padding-bottom: 32px;
  background-color: #f7f7f7;

  .menu-calendar {
    left: initial !important;
    right: 0 !important;
  }
  .rs-picker-daterange-menu {
    left: initial !important;
    right: 0 !important;
  }
  .rs-picker-menu {
    right: 0 !important;
    left: initial !important;
  }
`;

export const LeadsHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-family: Open Sans;
    font-weight: bold;
    font-size: 40px;
    line-height: 245.5%;
    color: #2f054d;
  }
`;

export const FilterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;
  & > img {
    cursor: pointer;
    margin-right: 16px;
  }
`;
export const StatusInfoContent = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      font-family: Open Sans;
      font-weight: normal;
      font-size: 12px;
      color: #676767;
    }
    h4 {
      font-family: Open Sans;
      font-weight: bold;
      font-size: 25px;
      color: #2f054d;
    }
  }
`;

export const SearchFilterContent = styled.div`
  margin: 2vh 0;
  min-height: 18vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  form {
    border: 1px solid #c9bfd1;
    border-radius: 10px;
    box-shadow: 1px 2px #c1c1c1;
    max-width: 65%;
    padding: 8px;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-right: 16px;
    button {
      background-color: #fff;
      cursor: pointer;
      width: 180px;
      height: 38px;
      box-sizing: border-box;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #2f054d;
      transition: all linear 0.4s;
      &:disabled {
        cursor: not-allowed;
        color: #f0f0f0;
        > span {
          color: gray;
          pointer-events: none;
        }
      }
      span {
        font-family: Open Sans;
        font-size: 14px;
        color: #2f054d;
        pointer-events: none;
      }
      img {
        margin-left: 8px;
        pointer-events: none;
      }
    }
    button:first-child {
      &:hover {
        opacity: 0.6;
      }
    }
    button:last-child {
      margin-left: 16px;
      background: #2f054d;
      border: 2px solid #2f054d;
      &:hover {
        opacity: 0.6;
      }
      span {
        color: #fff;
      }
    }
  }

  .image-button {
    cursor: pointer;
    border: none !important;
    background: #fff !important;
    width: 20% !important;
    height: 100% !important;
    border-radius: 15px;
    &:hover {
      opacity: 0.7;
    }
  }
`;
export const SearchButtons = styled.div`
  width: 40%;
  // border: 1px solid #676767;
  border-radius: 10px;
  height: 16vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const SearchInput = styled.div`
  width: 271px;
  height: 38px;
  border: 2px solid #c9bfd1;
  box-sizing: border-box;
  border-radius: 15px;
  & > input {
    width: 100%;
    height: 100%;
    border-radius: 15px 0 0 15px;
    padding-left: 8px;

    font-family: Open Sans;
    font-size: 16px;
    color: #676767;
    opacity: 0.5;
  }
  & > button {
    cursor: pointer;
    border: none !important;
    background: #fff !important;
    width: 20% !important;
    height: 100% !important;
    border-radius: 0 15px 15px 0;
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const FormStyled = styled.div`
  width: 100%;
  min-height: 52px;
  border-radius: 15px;
  flex-flow: row wrap;
  justify-content: space-around;

  div {
    flex-flow: column wrap;
    margin: 4px 8px;
  }

  input {
    // width: 100%;
    height: 100%;
    border-radius: 15px;
    padding-left: 8px;
    min-width: 150px;
    max-width: 200px;

    font-family: Open Sans;
    font-size: 16px;
    color: #676767;
    opacity: 0.5;
  }

  select {
    // width: 100%;
    height: 100%;
    min-width: 120px;
    max-width: 150px;
    border-radius: 15px;
    padding-left: 8px;

    font-family: Open Sans;
    font-size: 16px;
    color: #676767;
    opacity: 0.5;
  }

  button {
    cursor: pointer;
    width: 201px;
    height: 38px;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    min-width: 120px;
    max-width: 150px;
    align-items: center;
    justify-content: center;
    border: 2px solid #2f054d;
    transition: all linear 0.4s;
    margin-left: 16px;
    background: #2f054d;
    border: 2px solid #2f054d;
    color: #fff;
    &:hover {
      opacity: 0.6;
    }
    span {
      color: #fff;
    }
  }
`;
