import React from "react";
import {
  Redirect,
  Route as ReactDomRoute,
  RouteProps as ReactDomRouteProps,
} from "react-router-dom";
import { getLsUserToken } from "../localStorage/index";

//@ts-ignore
interface RouteProps extends ReactDomRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const user = getLsUserToken();
  return (
    <ReactDomRoute
      {...rest}
      render={({ location }) => {
        if (!user) {
          if (isPrivate) {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location },
                }}
              />
            );
          }
          //@ts-ignore
          return <Component />;
        } else {
          if (location.pathname === "/login") {
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location },
                }}
              />
            );
          }
          //@ts-ignore
          return <Component />;
        }
      }}
    />
  );
};

export default Route;
