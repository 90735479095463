import { DateRangePicker } from "rsuite";
import GlobalIconCard from "../../assets/icons/global_icon_purple.svg";
import LaptopIconCard from "../../assets/icons/laptop_icon_purple.svg";
import PersonIconCard from "../../assets/icons/person_icon_card.svg";

import {
  DashboardContainer,
  RelatoryHeader,
  FilterContent,
  StatusInfoContent,
  ChartContainer,
  SingleChart,
  ChartContent,
  MappingContainer,
  MappingCardContainer,
  MappingCard,
  ProductsContainer,
  ProductsContent,
} from "./styled";
import { BriaFooter } from "../../components/BriaFooter";
import { useCallback, useEffect, useState } from "react";
import { request } from "../../services/request";
import ReactApexChart from "react-apexcharts";
import { ageCalc, percentualConverter } from "../../utils/utils";
import { useAccountsSelected } from "../../hooks/useAccountsSelected";
//@ts-ignore

export const Dashboard: React.FC = () => {
  const [leads, setLeads] = useState<Array<any>>([{} as any]);
  const [visitants, setVisitants] = useState<Array<any>>([]);

  const [leadsFiltered, setLeadsFiltered] = useState<Array<any>>([]);
  const [visitantsFiltered, setVisitantsFiltered] = useState<Array<any>>([]);
  const [mql, setMql] = useState<number>(0);
  const [sql, setSql] = useState<number>(0);
  const [sqlPayment, setSqlPayment] = useState<number>(0);
  const [ongoing, setOngoing] = useState<number>(0);
  const [subscribed, setSubscribed] = useState<number>(0);
  const [leadsFiltered2, setLeadsFiltered2] = useState<Array<any>>([]);
  const [visitantsFiltered2, setVisitantsFiltered2] = useState<Array<any>>([]);
  const [cities, setCities] = useState<
    Array<{
      city: string;
      count: number;
      percentual?: number;
    }>
  >(
    [] as Array<{
      city: string;
      count: number;
      percentual?: number;
    }>
  );
  const [chanels, setChanels] = useState<
    Array<{
      chanel: string;
      count: number;
      percentual?: number;
    }>
  >(
    [] as Array<{
      chanel: string;
      count: number;
      percentual?: number;
    }>
  );
  const [ageGap, setAgeGap] = useState<
    Array<{
      age: string;
      count: number;
      percentual?: number;
    }>
  >(
    [] as Array<{
      age: string;
      count: number;
      percentual?: number;
    }>
  );
  const [products, setProducts] = useState<
    Array<{
      product: string;
      count: number;
      percentual?: number;
    }>
  >(
    [] as Array<{
      product: string;
      count: number;
      percentual?: number;
    }>
  );

  const [leadsFiltered3, setLeadsFiltered3] = useState<Array<any>>([]);

  const [chartOptions, setChartOptions] = useState<any>({
    series: [],
    options: {
      chart: {
        width: 350,
        type: "pie",
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });
  const [chartOptions2, setChartOptions2] = useState<any>({
    series: [],
    options: {
      chart: {
        width: 350,
        type: "pie",
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });
  const [chartOptions3, setChartOptions3] = useState<any>({
    series: [],
    options: {
      chart: {
        width: 350,
        type: "pie",
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });
  const { accountsSelected } = useAccountsSelected();

  const setGraphOptions = (
    series: Array<number>,
    labels: Array<string>,
    chart: number
  ) => {
    let options = {
      series: [...series],
      options: {
        chart: {
          width: 300,
          type: "pie",
        },
        legend: {
          position: "bottom",
        },
        colors: [
          "hsla(275, 88%, 16%, 1)",
          "hsla(8, 90%, 62%, 1)",
          "hsla(8, 90%, 72%, 1)",
        ],
        labels: [...labels],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 150,
              },
            },
          },
          {
            breakpoint: 800,
            options: {
              chart: {
                width: 250,
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                width: 290,
              },
            },
          },
        ],
      },
    };
    chart === 1 && setChartOptions(options);
    chart === 2 && setChartOptions2(options);
    chart === 3 && setChartOptions3(options);
  };

  const setVisitantsCardsMetrics = (visitantsRecieve: Array<any>) => {
    let cityCounter: Array<{
      city: string;
      count: number;
      percentual?: number;
    }> = [];
    let chanelCounter: Array<{
      chanel: string;
      count: number;
      percentual?: number;
    }> = [];
    visitantsRecieve.forEach((visitant: any) => {
      if (visitant && visitant.location && visitant.location.city) {
        if (
          cityCounter[0] &&
          cityCounter.find((city: any) => city.city === visitant.location.city)
        ) {
          cityCounter = cityCounter.map((city: any) => {
            if (city.city === visitant.location.city) {
              return {
                city: city.city,
                count: city.count + 1,
              };
            }
            return city;
          });
        } else {
          return cityCounter.push({
            city: visitant.location.city,
            count: 1,
          });
        }
      } else {
        if (
          cityCounter[0] &&
          cityCounter.find((city: any) => city.city === "Não Registrado")
        ) {
          cityCounter = cityCounter.map((city: any) => {
            if (city.city === "Não Registrado") {
              return {
                city: "Não Registrado",
                count: city.count + 1,
              };
            }
            return city;
          });
        } else {
          cityCounter.push({ city: "Não Registrado", count: 1 });
        }
      }
      ////Chanels

      if (visitant.params && visitant.params.utm_source) {
        if (
          chanelCounter[0] &&
          chanelCounter.find(
            (chanel: any) => chanel.chanel === visitant.params.utm_source
          )
        ) {
          chanelCounter = chanelCounter.map((chanel: any) => {
            if (chanel.chanel === visitant.params.utm_source) {
              return {
                chanel: chanel.chanel,
                count: chanel.count + 1,
              };
            }
            return chanel;
          });
        } else {
          return chanelCounter.push({
            chanel: visitant.params.utm_source,
            count: 1,
          });
        }
      } else {
        if (
          chanelCounter[0] &&
          chanelCounter.find(
            (chanel: any) => chanel.chanel === "Não Registrado"
          )
        ) {
          chanelCounter = chanelCounter.map((chanel: any) => {
            if (chanel.chanel === "Não Registrado") {
              return {
                chanel: "Não Registrado",
                count: chanel.count + 1,
              };
            }
            return chanel;
          });
        } else {
          chanelCounter.push({ chanel: "Não Registrado", count: 1 });
        }
      }
    });
    chanelCounter = chanelCounter.map((chanel: any) => {
      return {
        chanel: chanel.chanel,
        count: chanel.count,
        percentual: percentualConverter(chanel.count, visitantsRecieve.length),
      };
    });

    cityCounter = cityCounter.map((city: any) => {
      return {
        city: city.city,
        count: city.count,
        percentual: percentualConverter(city.count, visitantsRecieve.length),
      };
    });

    setChanels(
      chanelCounter.sort((a: any, b: any) => {
        return b.count - a.count;
      })
    );
    setCities(
      cityCounter.sort((a: any, b: any) => {
        return b.count - a.count;
      })
    );
  };

  const setLeadsCardMetrics = (leadsRecieve: Array<any>) => {
    let ageCounter: Array<{
      age: string;
      count: number;
      percentual?: number;
    }> = [];
    leadsRecieve.forEach((lead: any) => {
      if (lead.birthday) {
        if (
          ageCounter[0] &&
          ageCounter.find((age: any) => age.age === ageCalc(lead.birthday))
        ) {
          ageCounter = ageCounter.map((age: any) => {
            if (age.age === ageCalc(lead.birthday)) {
              return {
                age: ageCalc(lead.birthday),
                count: age.count + 1,
              };
            }
            return age;
          });
        } else {
          ageCounter.push({ age: ageCalc(lead.birthday), count: 1 });
        }
      } else {
        if (
          ageCounter[0] &&
          ageCounter.find((age: any) => age.age === "Não Registrado")
        ) {
          ageCounter = ageCounter.map((age: any) => {
            if (age.age === "Não Registrado") {
              return {
                age: "Não Registrado",
                count: age.count + 1,
              };
            }
            return age;
          });
        } else {
          ageCounter.push({ age: "Não Registrado", count: 1 });
        }
      }
    });
    ageCounter = ageCounter.map((age: any) => {
      return {
        age: age.age,
        count: age.count,
        percentual: percentualConverter(age.count, leadsRecieve.length),
      };
    });
    setAgeGap(
      ageCounter.sort((a: any, b: any) => {
        return b.count - a.count;
      })
    );
  };

  const setProductsCounter = (leadsRecieve: Array<any>) => {
    let productsCounter: Array<{
      product: string;
      count: number;
      percentual?: number;
    }> = [];
    leadsRecieve.forEach((lead: any) => {
      if (lead.selected_product) {
        if (
          productsCounter[0] &&
          productsCounter.find(
            (product: any) => product.product === lead.selected_product
          )
        ) {
          productsCounter = productsCounter.map((product: any) => {
            if (product.product === lead.selected_product) {
              return {
                product: lead.selected_product,
                count: product.count + 1,
              };
            }
            return product;
          });
        } else {
          productsCounter.push({ product: lead.selected_product, count: 1 });
        }
      }
    });
    productsCounter = productsCounter.map((product: any) => {
      return {
        product: product.product,
        count: product.count,
        percentual: percentualConverter(product.count, leadsRecieve.length),
      };
    });
    setProducts(
      productsCounter.sort((a: any, b: any) => {
        return b.count - a.count;
      })
    );
  };

  const getAllLeads = useCallback(async () => {
    const response = await request({
      showLoading: true,
      showSuccessMessage: false,
      timeout: 60000,
      method: "GET",
      path: `leads/all?accounts=${accountsSelected.join(",")}`,
    });
    if (!response.error) {
      setLeads(response.leads);
      setLeadsFiltered(response.leads);
      setLeadsCardMetrics(response.leads);
      setLeadsFiltered2(response.leads);
      setLeadsFiltered3(response.leads);
      setVisitants(response.visitants);
      setVisitantsFiltered(response.visitants);
      setVisitantsCardsMetrics(response.visitants);
      setMql(response.mql);
      setSql(response.sql);
      setSqlPayment(response.sql_payment);
      setOngoing(response.ongoing);
      setSubscribed(response.subscribed);
      setGraphOptions(
        [response.visitants.length, response.leads.length],
        ["Visitantes", "Leads"],
        1
      );
      setGraphOptions([response.mql, response.sql], ["MQL", "SQL"], 2);
      setGraphOptions(
        [response.sql_payment, response.ongoing, response.subscribed],
        ["Pagamentos SQL", "Ongoing", "Efetivado"],
        3
      );
    }
  }, [accountsSelected]);

  useEffect(() => {
    setVisitantsCardsMetrics(visitantsFiltered2);
  }, [visitantsFiltered2]);
  useEffect(() => {
    setLeadsCardMetrics(leadsFiltered2);
  }, [leadsFiltered2]);
  useEffect(() => {
    setProductsCounter(leadsFiltered3);
  }, [leadsFiltered3]);

  useEffect(() => {
    let new_mql = 0;
    let new_sql = 0;
    let new_sql_payment = 0;
    let new_ongoing = 0;
    let new_subscribed = 0;
    leadsFiltered.forEach((lead) => {
      lead.mql && (new_mql = new_mql + 1);
      lead.sql && (new_sql = new_sql + 1);
      lead.sql_payment && (new_sql_payment = new_sql_payment + 1);
      lead.ongoing && (new_ongoing = new_ongoing + 1);
      lead.subscribed && (new_subscribed = new_subscribed + 1);
    });
    setMql(new_mql);
    setSql(new_sql);
    setSqlPayment(new_sql_payment);
    setOngoing(new_ongoing);
    setSubscribed(new_subscribed);
    setGraphOptions(
      [visitantsFiltered.length, leadsFiltered.length],
      ["Visitantes", "Leads"],
      1
    );
    setGraphOptions([new_mql, new_sql], ["MQL", "SQL"], 2);
    setGraphOptions(
      [new_sql_payment, new_ongoing, new_subscribed],
      ["Pagamentos Sql", "Ongoing", "Efetivado"],
      3
    );
  }, [visitantsFiltered, leadsFiltered]);

  useEffect(() => {
    if (accountsSelected.length > 0) getAllLeads();
  }, [accountsSelected]);

  return (
    <DashboardContainer>
      <ChartContainer>
        <RelatoryHeader>
          <h2>Dashboard</h2>
          <FilterContent>
            {/* <img src={FilterIconPurple} alt="" /> */}
            {
              //@ts-ignore
              <DateRangePicker
                style={{
                  width: 280,
                  cursor: "pointer",
                }}
                menuClassName="menu-calendar"
                onClick={() => {
                  setTimeout(() => {
                    const calendar = document.querySelector(
                      ".menu-calendar"
                    ) as HTMLDivElement;
                    if (calendar) {
                      calendar.style.right = "0";
                      calendar.style.left = "initial";
                    }
                  }, 1);
                }}
                onChange={(e: any) => {
                  setVisitantsFiltered(
                    visitants.filter((visitant) => {
                      if (
                        new Date(visitant.createdAt).getTime() >=
                          new Date(e[0]).getTime() &&
                        new Date(visitant.createdAt).getTime() <=
                          new Date(e[1]).getTime()
                      ) {
                        return true;
                      }
                      return false;
                    })
                  );
                  setLeadsFiltered(
                    leads.filter((lead) => {
                      if (
                        new Date(lead.createdAt).getTime() >=
                          new Date(e[0]).getTime() &&
                        new Date(lead.createdAt).getTime() <=
                          new Date(e[1]).getTime()
                      ) {
                        return true;
                      }
                      return false;
                    })
                  );
                }}
              />
            }
          </FilterContent>
        </RelatoryHeader>
        <StatusInfoContent>
          <div>
            <p>Visitantes</p>
            <h4>{visitantsFiltered.length}</h4>
          </div>
          <div>
            <p>Leads</p>
            <h4>{leadsFiltered.length}</h4>
          </div>
          <div>
            <p>MQL</p>
            <h4>{mql}</h4>
          </div>
          <div>
            <p>SQL</p>
            <h4>{sql}</h4>
          </div>
          <div>
            <p>Pagamentos SQL</p>
            <h4>{sqlPayment}</h4>
          </div>
          <div>
            <p>Ongoing</p>
            <h4>{ongoing}</h4>
          </div>
          <div>
            <p>Efetivado</p>
            <h4>{subscribed}</h4>
          </div>
        </StatusInfoContent>
        <ChartContent>
          <SingleChart>
            {visitantsFiltered.length === 0 && leadsFiltered.length === 0 ? (
              <div className="error-chart">
                <span>Dados insuficientes</span>
              </div>
            ) : (
              //@ts-ignore
              <ReactApexChart
                options={chartOptions.options}
                series={chartOptions.series}
                type="pie"
                height={300}
              />
            )}
          </SingleChart>
          <SingleChart>
            {sql === 0 && mql === 0 ? (
              <div className="error-chart">
                <span>Dados insuficientes</span>
              </div>
            ) : (
              //@ts-ignore
              <ReactApexChart
                options={chartOptions2.options}
                series={chartOptions2.series}
                type="pie"
                height={300}
              />
            )}
          </SingleChart>
          <SingleChart>
            {sqlPayment === 0 && ongoing === 0 && subscribed === 0 ? (
              <div className="error-chart">
                <span>Dados insuficientes</span>
              </div>
            ) : (
              //@ts-ignore
              <ReactApexChart
                options={chartOptions3.options}
                series={chartOptions3.series}
                type="pie"
                height={300}
              />
            )}
          </SingleChart>
        </ChartContent>
      </ChartContainer>
      <MappingContainer>
        <RelatoryHeader>
          <h2>Mapeamento</h2>
          <FilterContent>
            {/* <img src={FilterIconPurple} alt="" /> */}
            {
              //@ts-ignore
              <DateRangePicker
                style={{
                  width: 280,
                  cursor: "pointer",
                }}
                menuClassName="menu-calendar"
                onClick={() => {
                  setTimeout(() => {
                    const calendar = document.querySelector(
                      ".menu-calendar"
                    ) as HTMLDivElement;
                    if (calendar) {
                      calendar.style.right = "0";
                      calendar.style.left = "initial";
                    }
                  }, 1);
                }}
                onChange={(e: any) => {
                  setVisitantsFiltered2(
                    visitants.filter((visitant) => {
                      if (
                        new Date(visitant.createdAt).getTime() >=
                          new Date(e[0]).getTime() &&
                        new Date(visitant.createdAt).getTime() <=
                          new Date(e[1]).getTime()
                      ) {
                        return true;
                      }
                      return false;
                    })
                  );
                  setLeadsFiltered2(
                    leads.filter((lead) => {
                      if (
                        new Date(lead.createdAt).getTime() >=
                          new Date(e[0]).getTime() &&
                        new Date(lead.createdAt).getTime() <=
                          new Date(e[1]).getTime()
                      ) {
                        return true;
                      }
                      return false;
                    })
                  );
                }}
              />
            }
          </FilterContent>
        </RelatoryHeader>
        <MappingCardContainer>
          <MappingCard>
            <header>
              <h4>Cidade</h4>
              <img src={GlobalIconCard} alt="" />
            </header>
            <div>
              {cities.map((city) => (
                <div key={city.city}>
                  <h3>{city.city}</h3>
                  {city.percentual && (
                    <h4>{city.percentual.toPrecision(3)}%</h4>
                  )}
                </div>
              ))}
            </div>
          </MappingCard>
          <MappingCard>
            <header>
              <h4>Canais</h4>
              <img src={LaptopIconCard} alt="" />
            </header>
            <div>
              {chanels.map((chanel) => (
                <div key={chanel.chanel}>
                  <h3>{chanel.chanel}</h3>
                  {chanel.percentual && (
                    <h4>{chanel.percentual.toPrecision(3)}%</h4>
                  )}
                </div>
              ))}
            </div>
          </MappingCard>
          <MappingCard>
            <header>
              <h4>Faixa etária</h4>
              <img src={PersonIconCard} alt="" />
            </header>

            {
              //@ts-ignore
              <ReactApexChart
                options={{
                  chart: {
                    height: 250,
                    width: 200,
                    type: "bar",
                    events: {
                      click: function (chart, w, e) {
                        // console.log(chart, w, e)
                      },
                    },
                  },
                  plotOptions: {
                    bar: {
                      columnWidth: "45%",
                      distributed: true,
                    },
                  },
                  colors: [
                    "hsla(275, 88%, 16%, 1)",
                    "hsla(275, 88%, 26%, 1)",
                    "hsla(275, 88%, 36%, 1)",
                    "hsla(8, 90%, 62%, 1)",
                    "hsla(8, 90%, 72%, 1)",
                  ],
                  dataLabels: {
                    enabled: false,
                  },
                  legend: {
                    show: false,
                  },
                  xaxis: {
                    categories: [...ageGap.map((age) => age.age)],
                    labels: {
                      style: {
                        fontSize: "12px",
                      },
                    },
                  },
                }}
                series={[
                  {
                    data: [...ageGap.map((age) => age.count)],
                  },
                ]}
                type="bar"
                height={200}
              />
            }
          </MappingCard>
        </MappingCardContainer>
      </MappingContainer>
      <ProductsContainer>
        <RelatoryHeader>
          <h2>Produtos mais selecionados</h2>
          <FilterContent>
            {/* <img src={FilterIconPurple} alt="" /> */}
            {
              //@ts-ignore
              <DateRangePicker
                style={{
                  width: 280,
                  cursor: "pointer",
                }}
                menuClassName="menu-calendar"
                onClick={() => {
                  setTimeout(() => {
                    const calendar = document.querySelector(
                      ".menu-calendar"
                    ) as HTMLDivElement;
                    if (calendar) {
                      calendar.style.right = "0";
                      calendar.style.left = "initial";
                    }
                  }, 1);
                }}
                onChange={(e: any) => {
                  setLeadsFiltered3(
                    leads.filter((lead) => {
                      if (
                        new Date(lead.createdAt).getTime() >=
                          new Date(e[0]).getTime() &&
                        new Date(lead.createdAt).getTime() <=
                          new Date(e[1]).getTime()
                      ) {
                        return true;
                      }
                      return false;
                    })
                  );
                }}
              />
            }
          </FilterContent>
        </RelatoryHeader>

        <ProductsContent>
          {products.map((product) => (
            <div key={product.product}>
              <h3>{product.product}</h3>
              {product.percentual && (
                <h4>{product.percentual.toPrecision(3)}%</h4>
              )}
            </div>
          ))}
        </ProductsContent>
      </ProductsContainer>

      <BriaFooter />
    </DashboardContainer>
  );
};
