/* eslint-disable react-hooks/exhaustive-deps */

import DownloadIconPurple from "../../assets/icons/download_icon_purple.svg";
import DownloadIconWhite from "../../assets/icons/download_icon_white.svg";
import Table from "../../components/Table/index";
import { format, parse, parseISO, subMonths } from "date-fns";
import { useAccountsSelected } from "../../hooks/useAccountsSelected";

import {
  LeadsContainer,
  LeadsHeader,
  StatusInfoContent,
  SearchFilterContent,
  FormStyled,
} from "./styled";
import { useEffect, useState } from "react";
import { request } from "../../services/request";

const formatDate = (value: string) => {
  const checkType = value.split("/");
  if (checkType.length > 1) {
    console.log(value);
    return value;
  } else {
    const _birthday = format(
      parse(value, "yyyy-MM-dd", new Date()),
      "dd/MM/yyyy"
    );
    return _birthday;
  }
};

const replaceData = (value: string) => {
  return value.replace(/\D/g, "");
};
interface IQualification {
  mql?: boolean;
  sql?: boolean;
  sql_payment?: boolean;
  ongoing?: boolean;
  subscribed?: boolean;
}
const setQualification = (value: IQualification) => {
  const qualifications = [];
  if (value.mql) {
    qualifications.push("MQL");
  }
  if (value.sql) {
    qualifications.push("SQL");
  }
  if (value.sql_payment) {
    qualifications.push("PGTO SQL");
  }
  if (value.ongoing) {
    qualifications.push("ONGOING");
  }
  if (value.subscribed) {
    qualifications.push("EFETIVADO");
  }
  return qualifications.join(", ");
};

const columns = [
  {
    name: "createdAt",
    label: "Acesso",
    options: {
      customBodyRender: (value: any) => {
        if (value)
          return (
            <>
              {format(parseISO(value), "dd/MM/yyyy")}{" "}
              {format(parseISO(value), "HH:MM:SS")}
            </>
          );
      },
    },
  },
  {
    name: "account",
    label: "Conta",
    options: {
      customBodyRender: (value: any) => {
        if (value)
          return (
            <>
              {value.subAccount.name}
              <br />
              {value.name}
            </>
          );
      },
    },
  },
  {
    name: "eventAccessId",
    label: "Campanhas",
    options: {
      display: false,
      customBodyRender: (value: any) => {
        if (value?.params)
          return (
            <>
              {value.params.utm_source}
              <br />
              {value.params.utm_medium}
            </>
          );
      },
    },
  },

  {
    name: "name",
    label: "Nome",
    options: {
      filter: true,
      sort: true,
    },
  },

  {
    name: "cellphone",
    label: "Celular",
    options: {
      customBodyRender: (value: any) => {
        if (value) return <>{replaceData(value)}</>;
      },
    },
  },
  {
    name: "email",
    label: "Email",
    options: {
      customBodyRender: (value: any) => {
        if (value)
          return (
            <>
              {value.split("@")[0]}
              <br />@{value.split("@")[1]}
            </>
          );
      },
    },
  },
  {
    name: "birthday",
    label: "Nascimento",
    options: {
      customBodyRender: (value: any) => {
        if (value) return <>{formatDate(value)}</>;
      },
    },
  },
  {
    name: "sex",
    label: "Gênero",
    options: {
      display: false,
      customBodyRender: (value: any) => {
        if (value)
          return (
            <>
              {value === "F" && "Feminino"}
              {value === "M" && "Masculino"}
            </>
          );
      },
    },
  },
  {
    name: "status_civil",
    label: "Estado Civil",
    options: {
      display: false,
    },
  },
  // {
  //   name: "profession",
  //   label: "Profissão",
  //   options: {
  //     display: false,
  //     customBodyRender: (value: any) => {
  //       if (value) return <>{value.title}</>;
  //     },
  //   },
  // },
  {
    name: "cpf",
    label: "CPF",
    options: {
      customBodyRender: (value: any) => {
        if (value) return <>{replaceData(value)}</>;
      },
    },
  },
  {
    name: "address",
    label: "Cidade",
    options: {
      customBodyRender: (value: any) => {
        if (value)
          return (
            <>
              {value?.city}/{value?.state}
            </>
          );
      },
    },
  },

  {
    name: "exposed",
    label: "Exposto",
    options: {
      display: false,
      customBodyRender: (value: any) => {
        if (value) return <>{value === "true" ? "Sim" : "Não"}</>;
      },
    },
  },
  {
    name: "selected_product",
    label: "Produto",
  },
  {
    name: "payment_option",
    label: "Pagamento",
    options: {
      display: false,
      customBodyRender: (value: any) => {
        if (value)
          return (
            <>
              {value === "1" && "Cartão de Crédito"}
              {value === "3" && "Débito em Conta"}
            </>
          );
      },
    },
  },
  {
    name: "sellerId",
    label: "Vendedor",
    options: {
      customBodyRender: (value: any) => {
        if (value) return <>{value.sellerName}</>;
      },
    },
  },
  {
    name: "briaContractStatus",
    label: "Status",
  },
  {
    name: "qualification",
    label: "Qualificação",
    options: {
      customBodyRender: (value: any) => {
        if (value) return <>{setQualification(value)}</>;
      },
    },
  },
];

export const Leads: React.FC = () => {
  const [leads, setLeads] = useState<Array<any>>([{} as any]);
  const [leadsFiltered, setLeadsFiltered] = useState<Array<any>>([{} as any]);
  const [search, setSearch] = useState<string>("");
  const [visitants, setVisitants] = useState<Array<any>>([]);
  const [visitantsFiltered, setVisitantsFiltered] = useState<Array<any>>([]);
  const [mql, setMql] = useState<number>(0);
  const [sql, setSql] = useState<number>(0);
  const [sqlPayment, setSqlPayment] = useState<number>(0);
  const [ongoing, setOngoing] = useState<number>(0);
  const [subscribed, setSubscribed] = useState<number>(0);
  const { accountsSelected, subAccounts } = useAccountsSelected();
  const [sellers, setSellers] = useState<Array<any>>([]);

  const [apiQuery, setApiQuery] = useState({
    status: "",
    initialDate: format(subMonths(new Date(), 1), "yyyy-MM-dd"),
    finalDate: format(new Date(), "yyyy-MM-dd"),
    briaContractStatus: "",
    sellerId: "",
    accounts: accountsSelected,
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    await getLeads();
  };

  const handleDownload = async (e: any) => {
    e.preventDefault();
    let queryParams = "";

    if (e.target.name === "pesquisa") {
      queryParams = `?accounts=${apiQuery?.accounts}&initialDate=${apiQuery?.initialDate}&finalDate=${apiQuery?.finalDate}&briaContractStatus=${apiQuery?.briaContractStatus}&sellerId=${apiQuery?.sellerId}&status=${apiQuery?.status}`;
    }

    if (e.target.name === "completo") {
      queryParams = `?accounts=${accountsSelected}`;
    }

    const blobFile = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "GET",
      responseType: "blob",
      path: `leads/xlsx${queryParams}`,
    });
    const url = window.URL.createObjectURL(new Blob([blobFile]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `leads-${e.target.name}.xlsx`);
    document.body.appendChild(link);
    link.click();
  };

  const changeApiQuery = (e: any) => {
    setApiQuery((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const getSellers = async () => {
    const response = await request({
      showLoading: false,
      showSuccessMessage: false,
      timeout: 60000,
      method: "GET",
      path: `sellers/subaccount/?subaccount=${accountsSelected}`,
    });
    if (!response.error) {
      setSellers(response);
    }
  };

  const getLeads = async () => {
    const response = await request({
      showLoading: true,
      showSuccessMessage: false,
      timeout: 60000,
      method: "GET",
      path: `leads/?initialDate=${apiQuery?.initialDate}&finalDate=${apiQuery?.finalDate}&status=${apiQuery?.status}&accounts=${apiQuery?.accounts}&briaContractStatus=${apiQuery?.briaContractStatus}&sellerId=${apiQuery?.sellerId}`,
    });

    if (!response.error) {
      setLeads(response.leads);
      setLeadsFiltered(response.leads);
      setVisitants(response.visitants);
      setVisitantsFiltered(response.visitants);
      setMql(response.mql);
      setSql(response.sql);
      setSqlPayment(response.sql_payment);
      setOngoing(response.ongoing);
      setSubscribed(response.subscribed);
      // getSellers();
    }
  };

  useEffect(() => {
    getLeads();
  }, []);

  useEffect(() => {
    if (accountsSelected.length > 0) {
      console.log("subAccounts", accountsSelected);
      getSellers();
    }
  }, [accountsSelected]);

  return (
    <LeadsContainer>
      <LeadsHeader>
        <h2>Leads</h2>
      </LeadsHeader>
      <StatusInfoContent>
        <div>
          <p>Total de Visitantes</p>
          <h4>{visitantsFiltered.length}</h4>
        </div>
        <div>
          <p>Total de leads</p>
          <h4>{leadsFiltered.length}</h4>
        </div>
        <div>
          <p>MQL</p>
          <h4>{mql}</h4>
        </div>
        <div>
          <p>SQL</p>
          <h4>{sql}</h4>
        </div>
        <div>
          <p>Pagamentos SQL</p>
          <h4>{sqlPayment}</h4>
        </div>
        <div>
          <p>Ongoing</p>
          <h4>{ongoing}</h4>
        </div>
        <div>
          <p>Efetivado</p>
          <h4>{subscribed}</h4>
        </div>
      </StatusInfoContent>
      <SearchFilterContent>
        <form onSubmit={handleSubmit}>
          <FormStyled>
            <div>
              <label htmlFor="status">Qualificação</label>
              <select
                id="status"
                name="status"
                value={apiQuery.status}
                onChange={changeApiQuery}
              >
                <option value=""></option>
                <option value="mql">MQL</option>
                <option value="sql">SQL</option>
                <option value="sql_pgto">Pagamento SQL</option>
                <option value="ongoing">Ongoing</option>
                <option value="efetivado">Efetivado</option>
              </select>
            </div>
            <div>
              <label htmlFor="accounts">Conta</label>
              <select
                id="accounts"
                name="accounts"
                onChange={changeApiQuery}
                value={apiQuery.accounts}
              >
                <option id="0" value={accountsSelected}></option>
                {subAccounts.map((value: any, index: number) => {
                  return (
                    <option key={index + 1} value={value._id}>
                      {value.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <label htmlFor="briaContractStatus">Status</label>
              <select
                id="briaContractStatus"
                name="briaContractStatus"
                value={apiQuery.briaContractStatus}
                onChange={changeApiQuery}
              >
                <option value=""></option>
                <option value="Ativo">Ativo</option>
                <option value="Inativo">Inativo</option>
                <option value="Suspenso">Suspenso</option>
              </select>
            </div>
            <div>
              <label htmlFor="sellerId">Vendedor</label>
              <select
                id="sellerId"
                name="sellerId"
                onChange={changeApiQuery}
                value={apiQuery.sellerId}
              >
                <option id="0" value=""></option>
                {sellers.map((value: any, index: number) => {
                  return (
                    <option key={index + 1} value={value._id}>
                      {value.sellerName}
                    </option>
                  );
                })}
              </select>
            </div>
          </FormStyled>
          <FormStyled>
            <div>
              <label htmlFor="initialDate">Data Inicial:</label>
              <input
                id="initialDate"
                type="date"
                name="initialDate"
                onChange={changeApiQuery}
                value={apiQuery.initialDate}
              />
            </div>
            <div>
              <label htmlFor="finalDate">Data Final:</label>
              <input
                id="finalDate"
                type="date"
                name="finalDate"
                onChange={changeApiQuery}
                value={apiQuery.finalDate}
              />
            </div>
            <div>
              <button onClick={getLeads}>Pesquisar</button>
            </div>
          </FormStyled>
        </form>

        <div>
          <button
            id="downloadPesquisa"
            name="pesquisa"
            onClick={handleDownload}
            disabled={!leads[0]}
          >
            <span>Download Pesquisa</span>
            <img src={DownloadIconPurple} alt="" />
          </button>
          <button
            id="downloadCompleto"
            name="completo"
            onClick={handleDownload}
          >
            <span>Download Completo</span>
            <img src={DownloadIconWhite} alt="" />
          </button>
        </div>
      </SearchFilterContent>
      {leadsFiltered && leadsFiltered.length > 0 && (
        <Table
          title=""
          data={leadsFiltered}
          columns={columns}
          search={search}
        />
      )}
    </LeadsContainer>
  );
};
