import CaledaryIconPurple from "../../assets/icons/calendary_icon_purple.svg";
import FilterIconPurple from "../../assets/icons/filter_icon_purple.svg";
import DownloadIconPurple from "../../assets/icons/download_icon_purple.svg";
import DownloadIconWhite from "../../assets/icons/download_icon_white.svg";
import SearchIconPurple from "../../assets/icons/search_icon_purple.svg";
import ArrowUpGrey from "../../assets/icons/arrow_up_icon_grey.svg";
import { useAccountsSelected } from "../../hooks/useAccountsSelected";
import { DateRangePicker } from "rsuite";

import {
  RelatoryContainer,
  RelatoryHeader,
  FilterContent,
  SearchFilterContent,
  SearchInput,
  RelatoryDataContent,
} from "./styled";
import { useCallback, useEffect, useState } from "react";
import { request } from "../../services/request";
//@ts-ignore
import CsvDownload from "react-json-to-csv";
import { percentualConverter } from "../../utils/utils";

enum RelatoryOptions {
  LEADS = "Leads",
  MAPPING = "Mapeamento",
  ASSIST_SELECTED = "Assistências mais selecionadas",
}

export const Relatory: React.FC = () => {
  const [leads, setLeads] = useState<Array<any>>([{} as any]);
  const [leadsFiltered, setLeadsFiltered] = useState<Array<any>>([{} as any]);
  const [relatorySelected, setRelatorySelected] = useState<
    Array<RelatoryOptions>
  >([]);
  const [visitants, setVisitants] = useState<Array<any>>([]);
  const [visitantsFiltered, setVisitantsFiltered] = useState<Array<any>>([]);
  const { accountsSelected } = useAccountsSelected();
  const [mql, setMql] = useState<number>(0);
  const [sql, setSql] = useState<number>(0);
  const [sqlPayment, setSqlPayment] = useState<number>(0);
  const [ongoing, setOngoing] = useState<number>(0);
  const [subscribed, setSubscribed] = useState<number>(0);

  const [products, setProducts] = useState<
    Array<{
      product: string;
      count: number;
      percentual?: number;
    }>
  >(
    [] as Array<{
      product: string;
      count: number;
      percentual?: number;
    }>
  );

  const setProductsCounter = (leadsRecieve: Array<any>) => {
    let productsCounter: Array<{
      product: string;
      count: number;
      percentual?: number;
    }> = [];
    leadsRecieve.forEach((lead: any) => {
      if (lead.selected_product) {
        if (
          productsCounter[0] &&
          productsCounter.find(
            (product: any) => product.product === lead.selected_product
          )
        ) {
          productsCounter = productsCounter.map((product: any) => {
            if (product.product === lead.selected_product) {
              return {
                product: lead.selected_product,
                count: product.count + 1,
              };
            }
            return product;
          });
        } else {
          productsCounter.push({ product: lead.selected_product, count: 1 });
        }
      }
    });
    productsCounter = productsCounter.map((product: any) => {
      return {
        product: product.product,
        count: product.count,
        percentual: percentualConverter(product.count, leadsRecieve.length),
      };
    });
    setProducts(
      productsCounter.sort((a: any, b: any) => {
        return b.count - a.count;
      })
    );
  };

  const getAllLeads = useCallback(async () => {
    const response = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "GET",
      path: `leads/all?accounts=${accountsSelected.join(",")}`,
    });
    if (!response.error) {
      setLeads(response.leads);
      setLeadsFiltered(response.leads);
      setProductsCounter(response.leads);
      setVisitants(response.visitants);
      setVisitantsFiltered(response.visitants);
      setMql(response.mql);
      setSql(response.sql);
      setSqlPayment(response.sql_payment);
      setOngoing(response.ongoing);
      setSubscribed(response.subscribed);
    }
  }, [accountsSelected]);

  useEffect(() => {
    let new_mql = 0;
    let new_sql = 0;
    let new_sql_payment = 0;
    let new_ongoing = 0;
    let new_subscribed = 0;
    leadsFiltered.forEach((lead) => {
      lead.mql && (new_mql = new_mql + 1);
      lead.sql && (new_sql = new_sql + 1);
      lead.sql_payment && (new_sql_payment = new_sql_payment + 1);
      lead.ongoing && (new_ongoing = new_ongoing + 1);
      lead.subscribed && (new_subscribed = new_subscribed + 1);
    });
    setMql(new_mql);
    setSql(new_sql);
    setSqlPayment(new_sql_payment);
    setOngoing(new_ongoing);
    setSubscribed(new_subscribed);
    setProductsCounter(leadsFiltered);
  }, [leadsFiltered]);

  useEffect(() => {
    if (accountsSelected.length > 0) getAllLeads();
  }, [accountsSelected]);

  return (
    <RelatoryContainer>
      <RelatoryHeader>
        <h2>Relatórios</h2>
        <FilterContent>
          {/* <img src={FilterIconPurple} alt="" /> */}
          {
            //@ts-ignore
            <DateRangePicker
              style={{
                width: 280,
                cursor: "pointer",
              }}
              menuClassName="menu-calendar"
              onClick={() => {
                setTimeout(() => {
                  const calendar = document.querySelector(
                    ".menu-calendar"
                  ) as HTMLDivElement;
                  if (calendar) {
                    calendar.style.right = "0";
                    calendar.style.left = "initial";
                  }
                }, 1);
              }}
              onChange={(e: any) => {
                setVisitantsFiltered(
                  visitants.filter((visitant) => {
                    if (
                      new Date(visitant.createdAt).getTime() >=
                        new Date(e[0]).getTime() &&
                      new Date(visitant.createdAt).getTime() <=
                        new Date(e[1]).getTime()
                    ) {
                      return true;
                    }
                    return false;
                  })
                );
                setLeadsFiltered(
                  leads.filter((lead) => {
                    if (
                      new Date(lead.createdAt).getTime() >=
                        new Date(e[0]).getTime() &&
                      new Date(lead.createdAt).getTime() <=
                        new Date(e[1]).getTime()
                    ) {
                      return true;
                    }
                    return false;
                  })
                );
              }}
            />
          }
        </FilterContent>
      </RelatoryHeader>

      <SearchFilterContent>
        <div>
          <CsvDownload
            data={[
              {
                visitantes: visitantsFiltered.length,
                leads: leadsFiltered.length,
                mql: mql,
                sql: sql,
                pagamentos_sql: sqlPayment,
                ongoing: ongoing,
                efetivados: subscribed,
              },
              ...products.map((product) => {
                return {
                  produto: product.product,
                  quantidade: product.count,
                };
              }),
            ]}
          >
            <span>Download período</span>
            <img src={DownloadIconPurple} alt="" />
          </CsvDownload>
          <CsvDownload
            data={[
              {
                visitantes: visitants.length,
                leads: leads.length,
                mql: mql,
                sql: sql,
                pagamentos_sql: sqlPayment,
                ongoing: ongoing,
                efetivados: subscribed,
              },
              ...products.map((product) => {
                return {
                  produto: product.product,
                  quantidade: product.count,
                };
              }),
            ]}
          >
            <span>Download completo</span>
            <img src={DownloadIconWhite} alt="" />
          </CsvDownload>
        </div>
      </SearchFilterContent>
      <RelatoryDataContent
        infoOpen={relatorySelected.includes(RelatoryOptions.LEADS)}
        onClick={() => {
          if (relatorySelected.includes(RelatoryOptions.LEADS)) {
            setRelatorySelected((relatory) =>
              relatory.filter((rel) => rel !== RelatoryOptions.LEADS)
            );
          } else {
            setRelatorySelected((relatory) => [
              ...relatory,
              RelatoryOptions.LEADS,
            ]);
          }
        }}
      >
        <div>
          <h2>{RelatoryOptions.LEADS}</h2>
          <img src={ArrowUpGrey} alt="" />
        </div>
        <div>
          <div>
            <h3>Total de Visitantes</h3>
            <h4>{visitantsFiltered.length}</h4>
          </div>
          <div>
            <h3>Total de leads</h3>
            <h4>{leadsFiltered.length}</h4>
          </div>
          <div>
            <h3>MQL</h3>
            <h4>{mql}</h4>
          </div>
          <div>
            <h3>SQL</h3>
            <h4>{sql}</h4>
          </div>
          <div>
            <h3>Pagamentos SQL</h3>
            <h4>{sqlPayment}</h4>
          </div>
          <div>
            <h3>Ongoing</h3>
            <h4>{ongoing}</h4>
          </div>
          <div>
            <h3>Efetivado</h3>
            <h4>{subscribed}</h4>
          </div>
        </div>
      </RelatoryDataContent>
      <RelatoryDataContent
        infoOpen={relatorySelected.includes(RelatoryOptions.ASSIST_SELECTED)}
        onClick={() => {
          if (relatorySelected.includes(RelatoryOptions.ASSIST_SELECTED)) {
            setRelatorySelected((relatory) =>
              relatory.filter((rel) => rel !== RelatoryOptions.ASSIST_SELECTED)
            );
          } else {
            setRelatorySelected((relatory) => [
              ...relatory,
              RelatoryOptions.ASSIST_SELECTED,
            ]);
          }
        }}
      >
        <div>
          <h2>Produtos</h2>
          <img src={ArrowUpGrey} alt="" />
        </div>
        <div>
          {products.map((product) => (
            <div>
              <h3>{product.product}</h3>
              <h4>{product.count}</h4>
            </div>
          ))}
        </div>
      </RelatoryDataContent>
    </RelatoryContainer>
  );
};
