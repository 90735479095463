import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  body ,html{
    margin: 0;
    padding: 0;
    font-family:  Open Sans, Helvetica, Sans-Serif;
    box-sizing:border-box;
  }
  h1,h2,h3,h4,h5,h6,p,span{
    margin: 0;
    padding:0;
  }
  button , input , textarea{
    padding:0;
    margin:0;
    outline: none;
    border:none;
  }

  .logo-client {
    max-width: 100px;
  }
`;

export default GlobalStyle;
