import styled from "styled-components";

export const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;

  h1 {
    font-family: Open Sans;

    font-weight: bold;
    font-size: 40px;

    color: #2f054d;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  div {
    & > p {
      font-family: Open Sans;
      font-size: 16px;

      color: #676767;
      margin-bottom: 4px;
    }
    & > textarea {
      border: 1px solid #999999;
      padding: 8px;
      border-radius: 10px;
      width: 90%;
      height: 40vh;
    }
    & > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 90%;

      button:hover {
        opacity: 0.6;
      }
      button {
        width: 162px;
        height: 54px;
        border: 2px solid #2f054d;
        transition: all linear 0.3s;

        border-radius: 12px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        span {
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 105.18%;

          letter-spacing: -0.03em;

          color: #2f054d;

          margin-right: 8px;
        }
        img {
          margin-left: 8px;
        }
      }
    }
  }
`;
