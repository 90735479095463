import styled from "styled-components";

export const FilterContainer = styled.div`
  height: 90px;

  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > div {
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 5px solid rgba(47, 5, 77, 0.3);
    width: 100%;

    & > h3 {
      margin-top: 22px;
      font-family: Open Sans;
      font-size: 18px;
      font-weight: normal;
      color: #2f054d;
    }
  }
`;

export const RightArea = styled.div`
  padding-right: 20px;
  position: relative;

  img {
    cursor: pointer;
  }
`;

export const AccountsToSelectedArea = styled.div`
  box-shadow: 0px 5px 13px -6px rgba(0, 0, 0, 0.2);
  width: 300px;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  position: absolute;
  right: 20px;
  top: 25px;
  display: flex;
  z-index: 10;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  padding: 20px;

  & > div {
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    width: 120px;
    height: 80px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    position: relative;

    img {
      max-width: 100px;
    }
  }
`;

export const CorrectIcon = styled.img`
  width: 15px;
  position: absolute;
  top: 3px;
  right: 3px;
`;
