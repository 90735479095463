import styled, { css } from "styled-components";
type SideBarProps = {
  barIsOpen: boolean;
  isSelected?: boolean;
};

export const SideBarContainer = styled.div`
  height: 100vh;

  ${({ barIsOpen }: SideBarProps) =>
    barIsOpen
      ? css`
          width: 14vw;
        `
      : css`
          width: 5vw;
        `}
  transition: all ease 0.4s;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const WrapperTouch = styled.div`
  height: 90px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  cursor: pointer;
  & > div {
    height: 100%;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 5px solid rgba(47, 5, 77, 0.3);

    & > img:first-child {
      margin-top: 22px;
      width: 60px;
      height: fit-content;
    }
    & > img:last-child {
      margin-top: 22px;
      width: 12px;
      margin-right: 4px;
      height: fit-content;
    }
  }
`;

export const NavOption = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  & > hr {
    display: none;
  }

  ${({ isSelected }: SideBarProps) =>
    isSelected &&
    css`
      & > hr {
        display: block;
        margin: 0;
        background: #f88675;
        border-radius: 2px;
        width: 6px;
        height: 100%;
      }
    `}
  &:hover {
    & > hr {
      display: block;
      margin: 0;
      background: #f88675;
      border-radius: 2px;
      width: 6px;
      height: 100%;
    }
    font-weight: bold;
  }

  div {
    height: 60px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 25px;
    }
    p {
      width: 88px;
      margin-left: 4px;
      transition: all ease 0.1s;
      font-family: Open Sans;
      font-size: 14px;
      color: #2f054d;
      ${({ isSelected }: SideBarProps) =>
        isSelected &&
        css`
          font-weight: bold;
        `}

      ${({ barIsOpen }: SideBarProps) =>
        barIsOpen
          ? css`
              display: inline;
            `
          : css`
              display: none;
            `}
    }
  }
`;
