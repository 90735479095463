import { SideNavBar } from "../../components/SideNavBar/index";
import { FilterTopSideBar } from "../../components/FilterTopSideBar/index";
import { DashboardContainer, DashboardContent } from "./styled";
import Route from "./../Route";
import { useEffect, useState } from "react";

enum DashboardPages {
  HOME = "Home",
  LEADS = "Leads",
  MY_VALUE = "Meus Valores",
  CONFIG = "Personalizar",
  SUB_ACCOUNT = "Subcontas",
  RELATORY = "Relatórios",
  FEEDBACK = "Feeback",
  CHANGE_PASSWORD = "Trocar Senha",
  LOGOUT = "Logout",
}

type masterPageProps = {
  component: React.FC;
  path: string;
  exact?: boolean;
  isPrivate?: boolean;
};

export const MasterPage: React.FC<masterPageProps> = (
  props: masterPageProps
) => {
  const [pageSelected, setPageSelected] = useState<DashboardPages>(
    DashboardPages.HOME
  );

  return (
    <DashboardContainer>
      <SideNavBar
        pageSelected={pageSelected}
        setPageSelected={setPageSelected}
      />
      <DashboardContent>
        <FilterTopSideBar></FilterTopSideBar>

        <Route {...props} />
      </DashboardContent>
    </DashboardContainer>
  );
};
